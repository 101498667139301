function enableGTMConsent(status) {
	window.dataLayer = window.dataLayer || [];

	window.dataLayer.push({
		'event': 'consent_update',
		'gdpr_consent': status, // Use 'granted' or 'denied'
		'analytics_storage': status === 'granted' ? 'granted' : 'denied',
		'ad_storage': status === 'granted' ? 'granted' : 'denied'
	});

	// Example of setting GTM's consent mode
	if (status === 'granted') {
		gtag('consent', 'update', {
			'ad_storage': 'granted',
			'analytics_storage': 'granted'
		});
	} else {
		gtag('consent', 'update', {
			'ad_storage': 'denied',
			'analytics_storage': 'denied'
		});
	}
}
